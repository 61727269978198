<template>
  <div class="stick-right pt-10 lg:pt-20">
    <div class="text-4xl lg:-mb-20 lg:pr-[206px] lg:text-6xl">{{ title }}</div>
    <Swiper
      :modules="modules"
      :slides-per-view="2.2"
      :spaceBetween="16"
      :speed="600"
      :parallax="true"
      :breakpoints="{
        1024: {
          spaceBetween: 32,
          slidesPerView: 3.2
        }
      }"
      class="app-slider relative mb-10 h-auto w-full p-0 lg:mb-16"
    >
      <div class="absolute right-0 top-2 z-2">
        <TestimonialsNav class="hidden lg:block" />
      </div>
      <SwiperSlide v-for="(item, idx) in sliderData" :key="idx">
        <slot :slide="item" />
      </SwiperSlide>
    </Swiper>
    <CallToAction v-if="Array.isArray(actions) && actions.length" :data="actions" />
  </div>
</template>

<script setup lang="ts">
import { Parallax, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { CtaItem } from '~~/types/button'
import 'swiper/css'

defineProps({
  sliderData: {
    type: Array,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  actions: {
    type: Array as PropType<CtaItem[]>,
    required: true
  }
})

const modules = [Parallax, A11y]
</script>

<style lang="postcss">
.app-slider .swiper-wrapper {
  @apply pt-10 lg:pt-40;
}
</style>
